
.tx-status {
    display: flex;
    align-items: center;
    font-weight: 500;

    &--failed {
        color: var(--page-tx-status-error-color);
    }

    &--success {
        color: var(--page-tx-status-success-color);
    }

    &__icon {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        fill: currentColor;
    }
}

.tx-flow-schematics {
    display: flex;
    align-items: center;
    border: 1px solid var(--page-tx-flow-diagram-border-color);
    overflow: hidden;
    border-radius: 8px;
}

.tx-flow-schematics-step {
    display: flex;
    align-items: center;

    &__inner {
        padding: 4px 10px;
        display: flex;
        flex-direction: column;
    }

    &__phase {
        font-size: 12px;
    }

    &::after {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        border-color: var(--page-tx-flow-diagram-border-color);
        border-width: 0 1px 1px 0;
        border-style: solid;
        transform: rotate(-45deg);
        margin: 0 8px 0 -28px;
        user-select: none;
        pointer-events: none;
    }

    &:last-child::after {
        display: none;
    }
}

.trace {
    margin: 0;
}

.card-timeago {
    color: var(--body-muted-text-color);
}

.trace {
    margin: 20px 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.trace::-webkit-scrollbar {
    display: none;
}

.transaction-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    &__items {
        box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        border: 1px solid var(--body-light-muted-color);
        padding: 0 12px;
        line-height: 36px;
        border-radius: 7px;
        color: var(--body-text-color);
        transition: all 0.2s;
    }

    &__items:hover {
        border: 1px solid var(--body-muted-text-color);
        color: var(--body-text-color);
        text-decoration: none;
    }

    &__icon {
        width: 24px;
        color: var(--body-text-color);

        svg {
            display: block;
            fill: currentColor;
        }
    }

    &__value {
        color: var(--body-text-color);
        font-size: 14px;
        text-transform: uppercase;
    }
}

.open-details {
    text-align: center;
    font-size: 14px;

    &__link {
        text-decoration: underline;
        text-decoration-style: dashed;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}
