
.nft-metadata-badge {
    display: flex;
    align-items: center;
    &__label {
        display: inline-flex;
        align-items: center;
        &__icon {
            fill: currentColor;
            margin-right: 0.35em;
            width: 16px;
            height: 16px;
            transform: translateY(-1px);
        }
        &__text {
            //font-weight: 500;
            margin-right: 0.35em;
        }
    }
}
