
.addressbook-dropdown {
    position: absolute;
    top: 45px;
    right: 30px;
    z-index: 1002;
    background: var(--card-background);
    border: 1px solid var(--card-border-color);
    box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
    border-radius: 12px;
    width: 320px;
    box-sizing: border-box;
    padding: 8px;
    font-size: 14px;
    max-height: 298px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;

    &--index {
        top: 38px;
        right: -8px;
    }

    &__header {
        box-sizing: border-box;
        width: calc(100% + 16px);
        margin-left: -8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding: 3px 14px 10px;
        margin-bottom: 6px;
        border-bottom: 1px solid var(--card-border-color);
        user-select: none;
    }

    &__empty {
        padding: 20px 0;
        text-align: center;
        user-select: none;
    }
}

.addressbook-dropdown-back-button {
    svg {
        margin-right: 4px;
        height: 9px;
        width: auto;
    }

    a {
        cursor: pointer;
        color: var(--blue-bright);

        &:hover {
            text-decoration: underline;
        }
    }
}

.addressbook-dropdown-input {
    box-sizing: border-box;
    width: 100%;
    padding: 8px 10px;
    color: inherit;
    position: relative;
    border-radius: 12px;
    background: var(--indexpage-search-background-color);
    border: 2px solid var(--card-border-color);

    &:focus {
        border-color: var(--app-search-field-focused-border-color) !important;
    }
}

.addressbook-dropdown-button {
    display: block;
    width: 100%;
    line-height: 32px;
    margin: 8px 0 0 0;
    background: var(--big-blue-button-background);
    cursor: pointer;
    color: #FFF;
    font-weight: 500;
    font-size: 14px;
    border-radius: 6px;
    text-align: center;
    -webkit-transition: all .15s;
    -o-transition: all .15s;
    transition: all .15s;
    user-select: none;

    &:hover {
        text-decoration: none;
        background: var(--big-blue-button-hover-background);
    }
}

.addressbook-dropdown-save-button {
    cursor: pointer;
    color: var(--blue-bright);
    user-select: none;

    &:hover {
        text-decoration: underline;
    }
}
