
.unlocks-tooltip__icon {
    fill: currentColor;
    width: 14px;
    height: 14px;
    margin: -4px 0 -4px 3px;
    transform: translateY(-2px);
    cursor: pointer;
    transition: .15s color;

    &:hover {
        color: var(--card-header-color);
        text-decoration: none;
    }
}

.unlocks-tooltip-wrapper {
    top: 45px;
    left: 12px;
    position: absolute;
    padding: 12px 14px;
    color: var(--body-text-color);
    border-radius: 12px;
    font-size: 13px;
    text-transform: none;
    background: var(--card-background);
    background-clip: border-box;
    border: 1px solid var(--card-border-color);
    box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
    max-width: 290px;
    z-index: 1001;
    font-weight: 400;
}
