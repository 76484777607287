
.tx-table-address-route {
    display: flex;
    align-items: center;
}

.tx-table-payload-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ui-address-event-overview {
    max-width: 180px;
}
@media (max-width: 599px) {
    .tx-table-action-icon {
        margin: 0 5px;
    }
}
.tx-table__td-no-top-border td,
.tx-table__td-no-top-border .tx-mobile-content{
    border-top: none!important;
}
