<template>
    <tr>
        <td class="tx-mobile-flex-content">
            <ui-link v-bind:to="{ name: 'jetton', params: { address: jetton }}" class="tx-link-jettons-account">
                <ui-round-image class="tx-token-cell-icon" size="tiny" v-bind:src="image"/>
                <div class="tx-mobile-content">
                    <div class="tx-mobile-content__top">
                        <div class="tx-mobile-content-row">
                            <span class="tx-mobile-content-jetton-name">{{ name || jetton }}</span>
                            <span>
                                {{ $ton(balance, decimals) }}
                                <span class="muted">  {{ truncateTicker(symbol) }}</span>
                            </span>
                            <!-- <ui-address v-bind:address="wallet.address"/> -->
                        </div>
                        <div class="tx-mobile-content-row">
                            <span class="muted" style="margin-bottom: 10px;">
                                <ui-format-price v-bind:price="jettonPrice" v-bind:symbol="'$'"/>
                            </span>
                            <span class="muted" style="margin-bottom: 10px;">
                                <ui-format-price v-bind:price="balancePrice" v-bind:symbol="'$'"/>
                            </span>
                        </div>
                    </div>
                </div>
            </ui-link>
        </td>
    </tr>
</template>

<script>
import { truncateTicker } from '~/helpers.js';

export default {
    props: {
        image: String,
        name: String,
        symbol: String,
        balance: String,
        decimals: Number,
        jetton: String,
        jettonWallet: String,
        jettonPrice: Number,
        balancePrice: Number,
    },

    methods: {
        truncateTicker,
        // getJettonRate(wallet) {
        //     const jettonsCount = parseFloat(formatTons(wallet.balance, wallet.jetton_meta.decimals).replace(/\s/g, '').replace(',', '.'));
        //
        //     const rate = this.rates.filter(({ id }) => id === wallet.jetton_address);
        //
        //     if (rate.length > 0) {
        //         return rate[0].price * jettonsCount;
        //     }
        //
        //     return null;
        // },
        //
        // getJettonPrice(wallet) {
        //     const rate = this.rates.filter(({ id }) => id === wallet.jetton_address);
        //     return rate[0]?.price || 0;
        // },
    },
};
</script>

<style>
.tx-token-cell-icon {
    margin-top: 1em !important;
    margin-left: 1em !important;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    border-radius: 32px !important;
    overflow: hidden;
}

.tx-token-cell-icon img {
    width: 100%;
    height: 100%;
}

.tx-mobile-content-jetton-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 20px;
}

.tx-link-jettons-account {
    color: var(--body-text-color);
    text-decoration: none!important;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100% !important;
    line-height: 1.45;
}
</style>
