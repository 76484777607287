
.ui-inline-nft {
    display: flex;
    align-items: center;
    background: var(--nft-preview-background);
    border-radius: 8px;
    margin: -4px 0;
    color: inherit;
    transition: .2s;
    &__left {
        width: 26px;
        height: 26px;
        border-radius: 8px 0 0 8px;
        overflow: hidden;
    }
    &__title {
        padding: 5px 10px;
        font-size: 13px;
        p {
            margin: 0;
            padding: 0;
        }
    }
    &__collection {
        margin: 0;
        font-size: 12px;
        color: var(--body-muted-text-color);
    }
    &:hover {
        background: var(--nft-preview-hover-background);
        color: var(--nft-preview-hover-color);
        text-decoration: none;
    }
}

@media screen and (max-width: 600px) {
    .ui-inline-nft{
        display: inline-flex;
        border-radius: 8px!important;
        padding: 0!important;
        margin: 8px 0 0 0!important;

        &__title {
            padding-right: 14px!important;
        }

        &__left {
            width: 52px;
            height: 52px;
        }
        &__collection--green {
            color: var(--chart-line-green-color);
            //opacity: .7;
        }
        &--green {
            color: var(--tx-table-icon-in-color);
            background-color: var(--tx-table-icon-in-background);
            border-radius: 8px !important;
        }
        &:hover {
            background: var(--nft-preview-hover-background);
            color: var(--body-text-color);
        }
    }
}

.ui-inline-nft-image {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--card-border-color);
    color: var(--body-text-color);
    &__img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1000;
        transform: scale(1.1);
    }
    &__svg {
        width: 14px;
        height: 14px;
        opacity: 0.8;
        z-index: 100;
    }
}
