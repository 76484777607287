<template>
    <div class="diagram-tooltip-wrapper">
        <div class="diagram-tooltip-content">
            <div class="diagram-tooltip-header">
                <span v-if="data.type === 'node'">Transaction on Address</span>
                <span v-else-if="data.type === 'link'">Message</span>
            </div>
            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
            <div
                v-for="(value, key) in data"
                v-if="key !== 'type' && value"
                v-bind:key="key"
                class="diagram-tooltip-row"
            >
                <template v-if="key === 'Address'">
                    <div class="diagram-tooltip-value">{{ shrinkAddress(value) }}</div>
                </template>
                <template v-else-if="key === 'TX Hash'">
                    <div class="diagram-tooltip-title">{{ key }}</div>
                    <div class="diagram-tooltip-value">{{ shrinkAddress(value) }}</div>
                </template>
                <template v-else>
                    <div class="diagram-tooltip-title">{{ key }}</div>
                    <div class="diagram-tooltip-value">{{ value }}</div>
                </template>
            </div>
            <!-- eslint-enable vue/no-use-v-if-with-v-for -->
        </div>
        <div class="diagram-tooltip-before">
            <tooltip-arrow />
        </div>
        <div class="diagram-tooltip-after">
            <tooltip-arrow />
        </div>
    </div>
</template>

<script>
import TooltipArrow from '@img/icons/tonscan/tooltip-bottom.svg?vue';
import { shrinkAddress } from '../../../helpers.js';

export default {
    name: 'Tooltip',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    mounted() {
        // console.log('Type Data:', this.data);
    },

    methods: {
        shrinkAddress(address) {
            return shrinkAddress(address);
        },
    },

    components: {
        TooltipArrow,
    },
};
</script>

<style>
.diagram-tooltip-title {
    line-height: 14px;
}

/* .diagram-tooltip-title, .diagram-tooltip-row {
    margin-bottom: 8px;
} */

.diagram-tooltip-title {
    color: var(--body-muted-text-color);
}

.diagram-tooltip-row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
}

.diagram-tooltip-row:last-child {
    margin-bottom: 0;
}

.diagram-tooltip-header {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}
</style>
