
.event-overview-row {
    .ui-inline-nft {
        padding: 0!important;
        margin: 0!important;
        border-radius: 8px!important;

        &:hover {
            background: var(--nft-preview-hover-background)!important;
            color: var(--nft-preview-hover-color)!important;
        }
    }
}

.tx-block-mobile-swap-items {
    align-items: center;
}

.tx-block-mobile-content-swap-wrapper {
    display: flex;
    flex-direction: column;
}

.tx-block-mobile-content__address {
    display: inline-flex;
    min-width: 0;
    flex-shrink: 1;
}

.tx-block-swap-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tx-block-nft-transfer {
    //padding-bottom: 5px;
}

.ui-address-event-overview {
    max-width: 180px;
}

@media (max-width: 599px) {
    .tx-table-action-icon {
        margin: 0 5px;
    }
}
