
.header {
    display: flex;
    background: var(--header-background);
    box-shadow: 0 1px 15px var(--header-box-shadow-color);
    height: 55px; // 54 + border
    border-bottom: 1px solid var(--header-border-bottom-color);
    box-sizing: content-box;
    position: sticky;
    margin-bottom: 18px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 80000;
    backdrop-filter: blur(20px);
    transition: .4s box-shadow ease;

    &__container {
        flex-grow: 1;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        width: 46px;
        height: 46px;
        margin: -10px 0;
        position: relative;
        bottom: -1px;
        color: var(--header-logo-color);
        transition: .1s color ease;
        &:hover {
            color: var(--body-text-color);
        }

        .header--mobile-search-visible & {
            display: none;
        }
    }

    &__links {
        height: 100%;
        margin-left: 20px;
        gap: 20px;
        display: flex;
    }
// <<<<<<< HEAD
// }

// .header-link {
//     transform: translateY(1.5px);
//     user-select: none;
//     height: 100%;
//     position: relative;

//     a {
//         display: flex;
//         align-items: center;
//         text-transform: uppercase;
//         font-weight: 500;
//         font-size: 14px;
//         color: var(--body-muted-text-color);
//         position: relative;
//         text-decoration: none !important;
//         height: 100%;
//         -webkit-transition: all .2s;
//         -o-transition: all .2s;
//         transition: all .2s;
//     }

//     :hover {
//         color: var(--body-text-color);
//     }

//     a::after {
//         content: '';
//         display: block;
//         width: 100%;
//         height: 0px;
//         background-color: var(--blue-bright);
//         z-index: 999;
//         position: absolute;
//         bottom: 1px;
//         left: 0;
//         border-radius: 2px 2px 0 0;
//         -webkit-transition: all .2s;
//         -o-transition: all .2s;
//         transition: all .2s;
//     }

//     .router-link-active {
//         color: var(--body-text-color);
//         &::after {
//             height: 3px;
//         }
//     }

// =======
// >>>>>>> master
}

.header-link {
    user-select: none;
    height: 100%;
    position: relative;

    :hover {
        color: var(--body-text-color);
    }

    &__anchor {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        color: var(--body-muted-text-color);
        position: relative;
        text-decoration: none !important;
        height: 100%;
        transition: color .15s;
        cursor: pointer;

        .header-link--active & {
            color: var(--body-text-color);
        }
    }

    $app_header_bar_active_line_height: 3px;

    &__line {
        display: block;
        width: 0;
        height: $app_header_bar_active_line_height;
        background-color: var(--blue-bright);
        z-index: 999;
        margin: 0 auto;
        position: absolute;
        bottom: 0px;
        transform: translateY(-0.5px);
        //margin: (($app_header_bar_active_line_height * -1) - 1px) auto 0; // compensate 1px of bottom border
        border-radius: 2px 2px 0 0;
        transition: width .15s;
        .header-link--active & {
            width: 100%;
        }
    }
}

.header-no-shadow {
    box-shadow: none;
    border-color: var(--card-border-color);
}

.header-main {
    background: transparent;
    border-bottom: 0;
    box-shadow: none;
    position: relative;
    height: 56px;
}
.header-setting {
    width: 24px;
    margin-left: 20px;
    cursor: pointer;
    color: var(--body-muted-text-color);
}
.header-setting svg {
    display: block;
}
// Prevent hover effect on small screens
// Because there's a bag when hover effect still apllies
// Even after menu was closed
@media screen and (min-width: 600px) {
    .header-setting:hover {
        color: var(--body-text-color);
    }
}
@media screen and (max-width: 600px) {
    .header-setting:active {
        color: var(--body-text-color);
    }
}
.header__links {
    display: flex;
    gap: 20px;
    height: 100%;
    margin-left: 15px;
    .header-link {
        transform: translateY(1.5px);
        user-select: none;
        text-transform: uppercase;
    }
    .header-link a {
        color: var(--body-muted-text-color);
        font-size: 14px;
        font-weight: 500;
        text-decoration: none!important;
    }
    .header-link a:hover {
        color: var(--body-text-color);
    }
    .header-link .router-link-active {
        color: var(--body-text-color);
    }
}
.header-burger-line,
.header-link a,
.header-setting {
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.header-burger {
    width: 28px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    height: 24px;
    .header-burger-line {
        height: 1px;
        background: var(--body-text-color);
    }
    .header-burger-line:nth-child(1) {
        width: 80%;
    }
    .header-burger-line:nth-child(2) {
        width: 100%;
    }
    .header-burger-line:nth-child(3) {
        width: 40%;
    }
}
.header-burger:hover .header-burger-line {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .header__links {
        display: none;
    }
}

.header-ear-right {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    &__buttons {
        display: flex;
        align-items: center;
    }
    &__button {
        cursor: pointer;
        color: #797979;
        padding: 18px 4px 16px 12px;
        margin: -12px -2px;
        fill: currentColor;
        width: 24px;
        height: 24px;
        &:hover {
            color: #444;
        }

        &--loopa {
            display: none;
            z-index: 80010;
        }
        &--settings, &--burger {
            transform: translateY(1px);
            z-index: 80001;
        }
    }
}

.fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translateY(10px);
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s, transform 0.3s;
}

@media screen and (max-width: 600px) {
    .header-ear-right__button:hover {
        color: #797979;
    }
    .header-ear-right__button:active {
        color: #444;
    }
}

@media screen and (max-width: 1250px) {
    .header__container {
        padding: 0 8px;
    }
}

@media screen and (max-width: 870px) {
    .header__links {
        display: none;
    }
}

.header-search {
    position: relative;
    width: auto;
    font-size: 14px;

    .search-input {
        min-width: 340px;
        background: var(--body-light-muted-color);
        color: var(--body-text-color);
        border-radius: 12px;
        border: 2px solid transparent;
        transition: .12s min-width ease,
                    .12s border-color ease;
        &__loopa {
            margin: 0 -8px 0 8px;
            opacity: 0.3;
        }
        &__input {
            padding: 9px 0 9px 16px;
            &::placeholder {
                color: #939394;
            }
            &::-webkit-input-placeholder {
                color: #939394;
            }
        }
        &--expanded {
            min-width: 480px;
        }
        &:focus-within {
            min-width: 480px;
            outline: 0 none;
            background: transparent;
        }
    }
    .search-input-controls {
        &__loader {
            transform: scale(0.9);
        }
    }

    .search-results {
        padding-bottom: 4px;
        padding-top: 4px;
        overflow: hidden;
        &__link {
            margin: 0 0 0 0;
        }
    }
    .search-result {
        &__left {
            margin-left: -4px;
        }
    }
}

@media screen and (max-width: 480px) {
    .header {
        margin-bottom: 12px;
    }

    .header-search {
        flex-grow: 1;
        .search-input {
            width: 100%;
            max-width: 100%;
            min-width: 0;
            &:focus-within {
                max-width: 100%;
                width: 100%;
                min-width: 0;
            }
        }
    }

    // Small screen: hide search input UNLESS user clicked the loopa button:
    .header-search {
        display: none;

        .header--mobile-search-visible & {
            display: flex !important;
        }
    }

    .header-ear-right {
        // Small screen - hide right ear buttons IF the search field is active:
        &__buttons {
            .header--mobile-search-visible & {
                display: none;
            }
        }

        // Small screen - uncover loopa button:
        &__button {
            &--loopa {
                display: flex;
            }
        }
    }
}
.new-badge {
    font-size: 9px;
    font-weight: 500;
    border-radius: 4px;
    padding: 3px 4px;
    display: inline;
    background-color: var(--filter-item-active);
    color: #FFFFFF;
    margin-left: 5px;

    &:hover {
        color: white;
    }
}

.addressbook-overlay {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100dvh;
    z-index: 1001;
}

.header-addressbook-container {
    display: inline-flex;
    position: relative;
    &__icon {
        padding: 0;
        margin: 0;
        width: 24px;
        height: 24px;
    }
}
