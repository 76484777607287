
.jetton-stats {
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
        text-transform: uppercase;
        font-weight: 500;
        color: var(--card-header-tab-color);
        margin-top: 12px;
    }
}

.jetton-stats-row {
    width: 100%;
    border-bottom: 1px solid var(--card-border-color);
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    align-items: flex-start;
    flex-direction: row;
    word-wrap: anywhere;
    line-height: 1.5;
    position: relative;

    &__name {
        font-weight: 500;
    }

    &__value > a {
        //font-weight: 500;
    }

    &:last-child {
        border-bottom: none;
    }
}

.jetton-owner a {
    font-weight: 400!important;
}

.jetton-owner a:hover {
    text-decoration: underline;
}

.jetton-copy-items-inner {
    display: flex;
    align-items: center;
}

.jetton-owner {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    width: 100%;
    white-space: nowrap;
    text-align: right;
}

.jetton-buy-buttons {
    display: flex;
    width: 100%;
    gap: 12px;
    height: 40px;
    margin-bottom: 16px;
    margin-top: 4px;

    &__btn {
        height: 100%;
        width: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }

    a:hover {
        opacity: .93;
        text-decoration: none!important;
    }

    &__dedust {
        color: var(--dedust-text-color);
        background-color: var(--dedust-bg-color);

        svg {
            height: 26px;
            width: auto;
        }
    }

    &__cryptobot {
        color: #fff;
        background-color: var(--big-blue-button-background);
        font-weight: 500;

        svg {
            height: 34px;
            transform: translateY(-1px);
            width: auto;
        }
    }
}
