
.ui-round-image {
    color: var(--card-background);
    flex-shrink: 0;
    &__image {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}
.ui-round-image-stub {
    border-radius: 50%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--ui-round-image-color);
    box-sizing: border-box;
    &__icon {
        width: 60%;
        margin-bottom: -6%;
        fill: currentColor;
    }
}

 .ui-round-image--tiny {
    .ui-round-image-stub {
        &__icon {
            width: 60%;
        }
    }
}
