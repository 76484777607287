
.stats-chart {
    height: 100%;
    width: 100%;
    display: flex;
    box-sizing: border-box;
}

.stats-section {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    // grid-template-rows: 120px 370px 340px repeat(2, 380px);
    grid-template-rows: 120px 400px 340px 340px 380px;
    grid-gap: 20px 20px;
}

.chart-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 16px;
    flex: 1;

    header {
        margin-bottom: 8px;
        color: var(--card-header-color);
    }

    &__value {
        font-size: 28px;
        font-weight: 500;
    }

    footer {
        opacity: .3;
    }
}

@for $i from 0 through 3 {
    .information-block[data-index='#{$i}'] {
        grid-column: #{$i * 3 + 1} / span 3;
    }
}

.ton-icon {
    fill: currentColor;
    width: 28px;
    height: 28px;
    margin-right: 2px;
    opacity: .3;
    transform: translate(-3px, 3px);
}

.contract-types-container {
    grid-column: 1 / 6;
}

.address-count-container {
    grid-column: 7 / span 6;
}

.transaction-count-container {
    grid-column: 1 / span 6;
}

.price-container,
.staking-container,
.validation-status-container {
    grid-column: 1 / 13;
}

@media screen and (max-width: 1099px) {
    .stats-section {
        // grid-template-rows: 108px 108px 400px 360px repeat(2, 300px) 400px auto;
        grid-template-rows: 108px 108px 360px 360px 300px 300px 400px auto;
    }

    .information-block[data-index='0'],
    .information-block[data-index='2'] {
        grid-column: 1 / 7;
    }

    .information-block[data-index='1'],
    .information-block[data-index='3'] {
        grid-column: 7 / 13;
    }

    .contract-types-container,
    .transaction-count-container,
    .address-count-container {
        grid-column: 1 / 13;
    }
}

@media screen and (max-width: 599px) {
    .ton-icon {
        width: 25px;
        height: 25px;
    }
    .stats-chart {
        padding: 5px 2px 5px 10px;
    }
    .stats-section {
        grid-gap: 0;
        // grid-template-rows:
        //     repeat(4, 108px)
        //     520px
        //     570px
        //     repeat(2, 300px)
        //     520px
        //     auto;

        grid-template-rows:
            repeat(4, 108px)
            520px
            300px
            300px
            300px
            520px
            auto;

        .card-title {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .chart-box {
        &__value {
            font-size: 24px;
        }
    }

    .price-container,
    .contract-types-container,
    .address-count-container,
    .transaction-count-container,
    .staking-container,
    .validation-status-container {
        padding-top: 20px;
    }

    .chart-bar {
        padding-left: 0!important;
    }

    .stats-section {
        .information-block {
            border-bottom-width: 0;
            border-radius: 0;
        }

        .information-block[data-index='0'] {
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
        }

        .information-block[data-index='3'] {
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
            border-bottom-width: 1px;
        }

        .information-block[data-index='0'],
        .information-block[data-index='1'],
        .information-block[data-index='2'],
        .information-block[data-index='3'] {
            grid-column: 1 / 13;
        }
    }
}
