
.price-change-indicator {
    font-weight: 500;
    &--red {
        color: #f74b4c;
    }

    &--green {
        color: #3fb950;
    }

    &--gray {
        color: #888888;
    }
}
