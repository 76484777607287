

.app {
    &__header {
        display: flex;
        margin-bottom: 25px;
    }

    &__logo {
        width: 140px;
        height: 140px;
        border-radius: 24px;
        background-size: cover;
        flex-shrink: 0;
    }

    &__heading {
        margin-left: 30px;
    }

    &__title {
        font-size: 24px;
        display: flex;
        gap: 9px;
        align-items: center;
        margin: 0;
        min-height: 24px; // For skeleton

        & svg {
            color: var(--app-icon-verified-color);
            width: 20px;
            height: 20px;
        }
    }

    &__short-description {
        margin-top: 8px;
        font-weight: 400;
        color: var(--app-short-description);
        max-width: 420px;
        line-height: 24px;
        // For skeleton loader
        min-width: 180px;
    }

    &__open-btn {
        margin-top: 14px;

        & a {
        display: block;
        width: fit-content;
        }
    }

    &__open-btn button {
        text-transform: uppercase;
        height: 40px;
        font-size: 14px;
    }

    &__open-btn a {
        text-decoration: none;
    }
}

@media screen and (max-width: 480px) {
    .app__open-btn {
        margin-top: 10px;
    }

    .app__logo {
        width: 96px;
        height: 96px;
        border-radius: 13px;
    }
}

