
.icon-currency {
    display: inline-block;
    fill: currentColor;
    margin: 0 3px 0 0;
    color: var(--body-muted-text-color);
}
.nft-meta-wrapper {
    margin: 0 -8px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 8px;
    padding-right: 8px;
    scroll-snap-type: x mandatory;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
    scrollbar-width: none;  /* Для Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Для Chrome, Safari и Opera */
    }
}
.nft-description-expander {
    background: linear-gradient(to left,var(--card-background) 35%,transparent);
    color: var(--blue-bright);
    cursor: pointer;
    margin-bottom: -10px;
    margin-top: -10px;
    padding: 2px 0;
    position: absolute;
    right: 0;
    width: 100%;
    text-align: right;
    bottom: 8px;
    z-index: 900;
    span {
        padding: 0 20px;
    }
}
.nft-collection-cover-container {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    background-color: var(--card-background);
    border: 1px solid var(--card-border-color);
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    max-width: 480px;
    align-self: flex-start;
    position: relative;
    &__header {
        position: relative;
        width: 100%;
        height: 88px;
        overflow: hidden;
        transform: scale(1.2);

        &--skeleton {
            display: block;
            background-color: var(--body-light-muted-color);
            width: 100%;
            height: 88px;
            position: absolute;
            top: 0;
            left: 0;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            margin: -1px;
            padding: 1px;
        }
    }
    &__content {
        width: 100%;
        padding: 12px 20px 11.4px;
        flex-grow: 1;
        box-sizing: border-box;

        &--text {
            padding-left: 115px;
            margin-bottom: 18px;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        max-width: 98px;
        max-height: 98px;
        border-radius: 16px;
        overflow: hidden;
        display: block;
        object-fit: contain;
        margin: auto;
        position: absolute;
        left: 20px;
        top: 50px;
        background: var(--address-tag-gray-background);
        border: 4px solid var(--card-background);
        img {
            height: 100%;
        }
        &.skeleton {
            border-radius: 8px;
            background-image: none;
            box-shadow: none;
            aspect-ratio: 1 / 1;
        }
    }
}
@media screen and (max-width: 991px) {
    .nft-collection-cover-container {
        &__content {
            padding-bottom: 16px;
        }
    }
}
@media screen and (max-width: 600px) {
    .nft-collection-cover-container {
        &__content {
            padding: 65px 12px 12px;
            &--text {
                padding-left: 0;
            }
        }
        &__image {
            max-width: 80px;
            max-height: 80px;
            top: 55px;
            left: 12px;
        }
    }
}
.nft-collection-header {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-width: 150px;
    &__title {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 5px;
        margin-top: 4px;
    }
    &__description {
        font-size: 14px;
        line-height: 1.4;
        color: var(--body-muted-text-color);
        position: relative;
        &--truncated {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
    }
}
@media screen and (max-width: 600px) {
    .nft-collection-header {
        &__title {
            font-size: 24px;
        }
    }
}
.nft-collection-error-message {
    display: block;
    text-align: center;
    padding: 16px;
    max-width: 480px;
    margin: 100px auto;
    &__title {
        font-size: 36px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -1px;
        margin: 0;
        padding: 0;
    }
    &__message {
        color: #909097;
        margin-top: 10px;
        font-size: 16px;
        line-height: 1.5;
    }
}
.nft-meta-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
    width: calc(100% - 8px);
    margin: 0 0 0 4px;
    border: 1px solid var(--card-border-color);
    border-radius: 12px;
}
@media screen and (max-width: 600px) {
    .nft-meta-items {
        margin-top: 16px;
        margin-left: 0;
        width: 100%;
    }
}
.nft-meta-item {
    width: auto;
    flex: 1 1 auto;
    flex-basis: 0;
    border-right: 1px solid var(--card-border-color);
    padding: 8px 0;

    &__title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
    }
    &__description {
        font-size: 12px;
        color: var(--body-muted-text-color);
    }

    &:last-child {
        border-right: none;
    }
}
@media screen and (max-width: 600px) {
    .nft-meta-item {
        flex: auto;
    }
}
.nft-collection-info {
    .card + .card {
        margin-top: 15px;
    }
}
.nft-link {
    margin-bottom: 0!important;
}
.item-count-number {
    user-select: none;
    padding-right: 12px;
    white-space: nowrap;
    font-weight: 500;
}
