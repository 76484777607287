<template>
    <div class="diagramOutline">
        <div ref="diagramContainer" class="diagramGrabber" v-bind:class="{ 'is-dragging': isDragging }" v-on:mousedown="startDrag" style="overflow: auto;">
            <div ref="diagram" class="diagramWrapper"></div>
        </div>
    </div>
</template>

<script>
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import Vue from 'vue';
import { formatTons } from '~/helpers';
import Tooltip from './DiagramTooltip.vue';

export default {
    name: 'Diagram',
    props: {
        trace: Object,
        connections: Array,
    },

    data() {
        return {
            treeData: null,
            links: null,
            isDragging: false,
        };
    },

    watch: {
        trace: 'loadData',
    },

    computed: {
        isDataNull() {
            return this.connections.length < 1 && Object.keys(this.trace).length === 0;
        },
    },

    mounted() {
        this.updateScrollGradient();
        this.$refs.diagramContainer.addEventListener('scroll', this.updateScrollGradient);
    },

    beforeDestroy() {
        d3.selectAll('.d3-tip').remove();
        this.$refs.diagramContainer.removeEventListener('scroll', this.updateScrollGradient);
    },

    methods: {
        loadData() {
            d3.selectAll('.d3-tip').remove();
            if (this.isDataNull) {
                d3.select(this.$refs.diagram).selectAll('*').remove();
            } else {
                d3.select(this.$refs.diagram).selectAll('*').remove();

                this.links = this.connections;
                this.treeData = this.trace;

                this.createDiagram();
            }
        },

        mountTooltip(data) {
            const Constructor = Vue.extend(Tooltip);
            const instance = new Constructor({ propsData: { data } }).$mount();
            return instance.$el.outerHTML;
        },

        createDiagram() {
            const { treeData } = this;
            const margin = { top: 60, right: 50, bottom: 60, left: 50 };
            const fixedNodeWidth = 220; const fixedNodeHeight = 60; const
                fixedNodeHeightMin = 60;

            const svgContainer = d3.select(this.$refs.diagram)
                .style('overflow', 'auto');

            const svg = svgContainer.append('svg').append('g');

            const defs = svg.append('defs');
            defs.append('marker')
                .attr('id', 'arrowhead')
                .attr('viewBox', '-10 -5 10 10')
                .attr('refX', 0)
                .attr('refY', 0)
                .attr('markerWidth', 6)
                .attr('markerHeight', 6)
                .attr('orient', 'auto')
                .append('path')
                .attr('d', 'M -10,-5 L 0,0 L -10,5')
                .attr('stroke', 'var(--diagram-line-color)')
                .attr('fill', 'var(--diagram-line-color)');

            // const nodeTooltip = d3Tip()
            //     .attr('class', 'd3-tip node-tooltip')
            //     .offset([-13, 0])
            //     .html(d => this.mountTooltip({
            //         'type': 'node',
            //         'Address': d.data.address,
            //         'TX Hash': d.data.transaction.hash,
            //         'Exit code': d.data.transaction.description.compute_ph.exit_code,
            //     }));

            const nodeTooltip = d3Tip()
                .attr('class', 'd3-tip node-tooltip')
                .offset([-13, 0])
                .html((d) => {
                    const tooltipData = {
                        'type': 'node',
                        'Address': d.data.address,
                        'TX Hash': d.data.transaction?.emulated ? undefined : d.data.transaction.hash,
                        // 'Status': d.data.transaction.description.compute_ph.success ? 'True' : 'False',
                    };

                    if (!d.data.transaction.description.compute_ph.success) {
                        if (d.data.transaction.description.compute_ph?.exit_code >= 0) {
                            tooltipData['Exit code'] = d.data.transaction.description.compute_ph?.exit_code;
                        } else {
                            tooltipData.Skipped = d.data.transaction.description.compute_ph?.skipped ? 'True' : 'False';
                        }
                    }

                    return this.mountTooltip(tooltipData);
                });

            const linkTooltip = d3Tip()
                .attr('class', 'd3-tip link-tooltip')
                .offset([-13, 0])
                .html((d) => {
                    const { opCode, label } = d.target.data;
                    return this.mountTooltip({
                        type: 'link',
                        Value: `${formatTons(d.target.data.value)} TON`,
                        OpCode: opCode,
                        Operation: label,
                    });
                });

            svg.call(nodeTooltip);
            svg.call(linkTooltip);

            const root = d3.hierarchy(treeData);
            const treeLayout = d3.tree().nodeSize([fixedNodeHeight, fixedNodeWidth]);
            treeLayout(root);

            const updateNodesPosition = (node) => {
                if (node.children && node.children.length > 5) {
                    const subtree = d3.hierarchy(node.data);
                    d3.tree().nodeSize([fixedNodeHeightMin, fixedNodeWidth])(subtree);
                    subtree.each((d, i) => {
                        if (i > 0) {
                            const correspondingNode = root.descendants().find(n => n.data === d.data);
                            if (correspondingNode) {
                                correspondingNode.x = d.x;
                            }
                        }
                    });
                }
                if (node.children) node.children.forEach(updateNodesPosition);
            };

            updateNodesPosition(root);

            const isBranched = node => node.children && node.children.length > 1;

            // Здесь регулируется разветвление
            const getLinkPath = (d) => {
                const sourceX = d.source.x;
                const sourceY = d.source.y;
                const targetX = d.target.x;
                const targetY = d.target.y;

                const markerEnd = 45;
                const markerStart = 45;

                if (isBranched(d.source)) {
                    const children = d.source.children;
                    const index = children.indexOf(d.target);
                    const isFirst = index === 0;
                    const isLast = index === children.length - 1;
                    const isOddCount = children.length % 2 !== 0;
                    const isCenterPoint = isOddCount && index === Math.floor(children.length / 2);

                    if (isFirst) {
                        const vertShift = sourceX - targetX - 10;
                        return `M${sourceY},${sourceX}V${sourceX - vertShift}a10,10 0,0,1 10,-10H${targetY - markerEnd}`;
                    } if (isLast) {
                        const vertShift = targetX - sourceX - 10;
                        return `M${sourceY},${sourceX}V${sourceX + vertShift}a10,10 0,0,0 10,10H${targetY - markerEnd}`;
                    } if (isCenterPoint) {
                        return `M${sourceY},${sourceX}H${targetY - markerEnd}`;
                    }
                    const vertShift = targetX - sourceX;
                    return `M${sourceY},${sourceX + vertShift}H${targetY - markerEnd}`;
                }

                return `M${sourceY + markerStart},${sourceX}H${targetY - markerEnd}`;
            };

            svg.selectAll('.link')
                .data(root.links())
                .enter().append('path')
                .attr('class', 'link')
                .attr('d', getLinkPath)
                .attr('fill', 'none')
                .attr('stroke', 'var(--diagram-line-color)')
                .attr('marker-end', 'url(#arrowhead)');

            const handleMouseOver = (event, d) => {
                const name = d.data.name;
                d3.selectAll('.line-circle')
                    .filter(function () {
                        return this.innerText && this.innerText === name;
                    })
                    .classed('highlight', true);
            };

            const handleMouseOut = (event, d) => {
                const name = d.data.name;
                d3.selectAll('.line-circle')
                    .filter(function () {
                        return this.innerText && this.innerText === name;
                    })
                    .classed('highlight', false);
            };

            const nodeGroups = svg.selectAll('.node-group')
                .data(root.descendants())
                .enter().append('g')
                .attr('class', 'node-group')
                .attr('transform', d => `translate(${d.y - 35},${d.x - 15})`)
                .on('mouseover', (event, d) => {
                    nodeTooltip.show(d, event.currentTarget);
                    handleMouseOver(event, d);
                })
                .on('mouseout', (event, d) => {
                    nodeTooltip.hide(d, event.currentTarget);
                    handleMouseOut(event, d);
                })
                .on('click', (event, d) => {
                    if (!d.data?.transaction?.emulated) {
                        this.$emit('node-clicked', d.data);
                        d3.selectAll('.line-circle-wrapper').classed('line-select', false);
                        d3.select(event.currentTarget).select('.line-circle-wrapper').classed('line-select', true);
                    }
                });

            nodeGroups.append('foreignObject')
                .attr('class', 'node')
                .attr('width', 80)
                .attr('height', 30)
                .append('xhtml:div')
                .html(d => `
                    <div class="line-circle-wrapper">
                        <div class="line-circle ${!d.data.status ? 'line-circle-red' : ''} ${d.data.transaction.emulated ? 'line-circle-faded' : ''}">${d.data.name}</div>
                    </div>
                `);

            const linkTextGroups = svg.selectAll('.link-text-group')
                .data(root.links())
                .enter().append('g')
                .attr('class', 'link-text-group')
                .attr('transform', (d) => {
                    const x = Math.min(d.source.y, d.target.y) + 60;
                    const y = (d.source.x < d.target.x)
                        ? Math.max(d.source.x, d.target.x) - 20
                        : Math.min(d.source.x, d.target.x) - 20;
                    return `translate(${x},${y})`;
                })
                .on('mouseover', function (event, d) { linkTooltip.show(d, this); })
                .on('mouseout', function (event, d) { linkTooltip.hide(d, this); });

            linkTextGroups.append('foreignObject')
                .attr('width', 110)
                .attr('height', 40)
                .append('xhtml:div')
                .html(d => `
                    <div class="link-container">
                        <div class="link-value">${formatTons(d.target.data.value)} TON</div>
                        <div class="link-label">${d.target.data.label}</div>
                    </div>
                `);

            const gBBox = svg.node().getBBox();
            const newHeight = gBBox.height + margin.top + margin.bottom;
            const newWidth = gBBox.width + margin.left + margin.right;

            svgContainer.select('svg').attr('height', newHeight).attr('width', newWidth);
            svg.attr('transform', `translate(${(newWidth - gBBox.width) / 2 - gBBox.x}, ${(newHeight - gBBox.height) / 2 - gBBox.y})`);

            this.$refs.diagram.style.width = `${newWidth}px`;
            this.$refs.diagram.style.height = `${newHeight}px`;
        },

        updateScrollGradient() {
            const { scrollLeft, scrollWidth, clientWidth } = this.$refs.diagramContainer;
            this.$refs.diagramContainer.classList.toggle('no-left-gradient', scrollLeft === 0);
            this.$refs.diagramContainer.classList.toggle('no-right-gradient', scrollLeft + clientWidth >= scrollWidth);
        },

        startDrag(event) {
            this.isDragging = true;
            this.startX = event.pageX - this.$refs.diagramContainer.offsetLeft;
            this.scrollLeft = this.$refs.diagramContainer.scrollLeft;

            document.addEventListener('mousemove', this.onDrag);
            document.addEventListener('mouseup', this.stopDrag);
        },

        onDrag(event) {
            const x = event.pageX - this.$refs.diagramContainer.offsetLeft;
            this.$refs.diagramContainer.scrollLeft = this.scrollLeft - (x - this.startX);
            this.$refs.diagramContainer.style.cursor = 'grabbing';
        },

        stopDrag() {
            this.isDragging = false;
            document.removeEventListener('mousemove', this.onDrag);
            document.removeEventListener('mouseup', this.stopDrag);
            this.$refs.diagramContainer.style.cursor = 'grab';
        },
    },
};
</script>

<style>
svg {
    background-color: transparent;
}

.link-value {
    font-size: 12px;
    color: var(--body-text-color);
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.link-label {
    font-size: 12px;
    color: var(--body-muted-text-color);
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
    white-space: nowrap;
    text-align: center;
}

.link-circle, .link-label, .link-value {
    cursor: default;
}

.link-container {
    display: flex;
    flex-direction: column;
    height: 40px;
    justify-content: space-between;
}

.d3-tip {
    padding: 12px 14px;
    color: var(--body-text-color);
    border-radius: 12px;
    font-size: 13px;

    background: var(--card-background);
    background-clip: border-box;
    border: 1px solid var(--card-border-color);
    box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
}

.diagram-tooltip-before {
    position: absolute;
    width: 25px;
    height: 6px;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--card-border-color);
    z-index: 0;
}

.diagram-tooltip-after {
    position: absolute;
    width: 20px;
    height: 10px;
    bottom: -6.1px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--card-background);
    z-index: 5;
}

.diagramOutline {
    position: relative;
    overflow: hidden;
}

.diagramGrabber {
    cursor: grab;
}

.diagramGrabber.is-dragging {
    cursor: grabbing;
}

.diagramGrabber::before,
.diagramGrabber::after {-webkit-transition: all .2s;-o-transition: all .2s;transition: all .2s;}

.diagramGrabber::before,
.diagramGrabber::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    height: calc(100% - 10px);
    pointer-events: none;
}

.diagramGrabber::before {
    left: 0;
    background: linear-gradient(to right, var(--body-background), rgba(255, 255, 255, 0));
}

.diagramGrabber::after {
    right: 0;
    background: linear-gradient(to left, var(--body-background), rgba(255, 255, 255, 0));
}

.diagramGrabber.no-left-gradient::before {
    opacity: 0;
}

.diagramGrabber.no-right-gradient::after {
    opacity: 1;
}

.diagramGrabber, .diagramWrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.diagramGrabber::-webkit-scrollbar, .diagramWrapper::-webkit-scrollbar {
  display: none;
}

.diagramWrapper {
    display: flex;
    overflow: hidden;
    margin: auto;
    justify-content: center;
    flex-shrink: 0;
    user-select: none;
}

.link-container:after, .node:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.line-circle {
    width: 85px;
    height: 30px;
    font-size: 14px;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid var(--diagram-circle-color);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: var(--body-background);
    color: var(--blue-bright);
    font-weight: 400;
    cursor: pointer!important;
    z-index: 5;
}

.line-circle-red {
    border-color: var(--diagram-circle-red-color);
    color: var(--page-tx-status-error-color);
}

.line-circle-faded {
    color: var(--body-muted-text-color);
    border-color: var(--card-border-color);
}

.line-circle-faded::after {
    content: "";
    border-radius: 6px;
    background: linear-gradient(90deg, rgba(130, 130, 130, 0.04) 8%, rgba(130, 130, 130, 0.08) 18%, rgba(130, 130, 130, 0.04) 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    width: calc(100% + 1px);
    height: calc(100% - 4px);
    display: block;
    position: fixed;
    top: 2px;
    left: 2px;
}

.line-circle-faded--loader {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 16px;
    height: 16px;
    color: var(--diagram-circle-color);
}

.line-circle-faded--loader svg {
    width: 16px;
    height: 16px;
    color: inherit;
}

.node-group, .link-text-group {
    cursor: pointer;
}

.line-circle.highlight:not(.line-circle-faded) {
    background: var(--diagram-line-color-hover);
}

.line-circle-red.highlight {
    background: var(--fail-status-transaction-background);
}

.node {
    overflow: visible;
}

.line-select .line-circle {
    background: var(--blue-bright);
    border-color: var(--blue-bright);
    color: #fff;
}

.line-select .line-circle-red {
    background: var(--page-tx-status-error-color);
    border-color: var(--page-tx-status-error-color);
    color: #fff;
}

@media (max-width: 640px) {
    .d3-tip {
        display: none!important;
    }
}
</style>
