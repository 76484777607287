
.tx-pending-icon {
    animation: 2s pendingRotation infinite linear;
}
@keyframes pendingRotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.amount-or-action {
    display: inline-flex;
    gap: 12px;
}
.comment-tooltip {
    position: absolute;
    object-position: bottom;
    right: 40px;
    height: auto;
    top: 6px;
    border: 1px solid var(--card-border-color);
    border-radius: 12px;
    box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
    padding: 12px 14px;
    background-color: var(--body-background);
    z-index: 99999;
    transition: .3s;
    max-width: 300px;

    // Make triangle tail
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        right: -8px;
        width: 8px;
        height: 8px;
        background: var(--body-background);
        border-right: 1px solid var(--card-border-color);
        border-bottom: 1px solid var(--card-border-color);
        transform: rotateZ(-45deg) translateX(-4.3px);
    }
}

// Make timestamp for events table, so addreses would be in the center
.wide-timestamp {
    min-width: 220px;
}

.comment-tooltip-animation-enter-to, .comment-tooltip-animation-leave {
    opacity: 1;
}
.comment-tooltip-animation-enter, .comment-tooltip-animation-leave-to {
    opacity: 0;
}
.tx-table-time {
    position: relative;
    cursor: default;

    &__cell {
        display: flex;
        align-items: center;
        max-width: 100%;
        box-sizing: border-box;
        width: 100%;
    }

    &__value {
        display: inline-block;
        position: relative;
        margin: 0 18px;
    }
}

@media screen and (max-width: 1149px) {
    .wide-timestamp {
        min-width: auto;
    }
}
