
.collection-social-links {
    display: flex;
    position: absolute;
    right: 12px;
    top: 6px;
    z-index: 5;
    gap: 10px;

    &__link {
        display: block;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        cursor: pointer;
        color: white;
        fill: white;
        background-color: var(--body-light-muted-color);
        backdrop-filter: blur(5px);

        svg {
            width: 20px;
            height: 20px;
            margin-top: 4px;
            margin-left: 4px;
        }
    }
}

.collection-social-links--mobile {
    display: flex;
    position: absolute;
    right: 12px;
    top: 108px;
    z-index: 5;
    gap: 12px;

    &__link {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        cursor: pointer;
        color: white;
        fill: white;
        //background-color: var(--body-light-muted-color);
        backdrop-filter: blur(5px);
        border: 1px solid var(--card-border-color);

        svg {
            width: 22px;
            height: 22px;
            margin-top: 5px;
            margin-left: 5px;
        }
    }
}
